import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import ProjectCard from "../../components/project-card"
import Onyx from "../../images/projects/onyx-design/cover.jpg"
import GrownAs from "../../images/projects/grownas/cover.jpg"
import HomeDesktop from "../../images/projects/latimer-app/home.mp4"

const aurisPage = () => (
  <Layout>
    <SEO title="Latimer.app" />
    <WorkInfo 
      title="Latimer.app"
      link="latimer.app"
      team1="Bailey Latimer"
      challenge="An experiment to build the fastest e-commerce site possible using Gatsby.js + Shopify."
      solution="An experiment to build the fastest e-commerce site possible using Gatsby.js + Shopify."/>
      

      <video class="mb-6"
      loop
      muted
      autoPlay>
      <source src={HomeDesktop} type="video/mp4" />
    </video>

    <StaticImage class="mb-20" src="../images/projects/latimer-app/cover.jpg" alt="Latimer Design Hero" aspectRatio={16/9} layout={"fullWidth"}/>


    <div class="adv-gallery-v3 mb-20">
      <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
      <ul class="columns-1 lg:columns-2">
      <ProjectCard classes="" title="GrownAs Mac" url="grownas-mac"  description="Motion-focused e-commerce experience for the cheesiest vegan mac available. Developed on Shopify in a week." cover={GrownAs}  role="Design + Development"/>

      <ProjectCard classes="" title="ONYX Design" url="onyx-design"  description="Rebrand + portfolio redesign for a web development agency based in Los Angeles." cover={Onyx}  role="Design + Development + Branding"/>
      </ul>
    </div>

  </Layout>
)

export default aurisPage
